<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="columns is-vcentered is-mobile mr-2" to="/">
        <div class="column is-narrow">
          <figure class="image is-64x64">
            <img src="./../assets/tassel_logo.png" />
          </figure>
        </div>
        <div class="column is-narrow" id="logo_text_col">
          <h1 class="title is-2">assel</h1>
        </div>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': menu_open }"
        @click="menu_open = !menu_open"
        aria-label="menu"
        aria-expanded="false"
        data-target="navMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navMenu" class="navbar-menu" :class="{ 'is-active': menu_open }">
      <div class="navbar-start" v-if="store.isLoggedIn">
        <router-link to="/" class="navbar-item is-size-4" @click="disable_nav">
          Study
        </router-link>
        <router-link
          to="/play"
          class="navbar-item is-size-4"
          @click="disable_nav"
        >
          Play
        </router-link>
        <router-link
          to="/customize"
          class="navbar-item is-size-4"
          @click="disable_nav"
        >
          Customise
        </router-link>
        <router-link
          to="/teach"
          class="navbar-item is-size-4"
          @click="disable_nav"
        >
          Teach
        </router-link>
      </div>
      <div class="navbar-start" v-else>
        <router-link to="/" class="navbar-item is-size-4" @click="disable_nav">
          About
        </router-link>
        <router-link
          to="/pricing"
          class="navbar-item is-size-4"
          @click="disable_nav"
        >
          Pricing
        </router-link>
        <router-link
          to="/contact"
          class="navbar-item is-size-4"
          @click="disable_nav"
        >
          Contact Us
        </router-link>
      </div>

      <div class="navbar-end" v-if="store.isLoggedIn">
        <div class="navbar-item">
          <div id="user_profile_card">
            <div class="columns is-variable is-2 is-mobile is-vcentered">
              <div class="column"><div id="profile_circle"></div></div>
              <div class="column has-text-weight-bold">
                {{ store.username }}
              </div>
              <div class="column">
                <img src="../assets/gold-coin.svg" class="profile_currency" />
              </div>
              <div class="column has-text-weight-bold">
                {{ store.currency.gold }}
              </div>
              <div class="column">
                <img src="../assets/silver-coin.svg" class="profile_currency" />
              </div>
              <div class="column has-text-weight-bold">
                {{ store.currency.tokens }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-end" v-else>
        <div class="navbar-item">
          <router-link to="/login">
            <button class="button purple-button-outline" @click="disable_nav">
              Log in
            </button>
          </router-link>
        </div>
        <div class="navbar-item">
          <router-link to="/register">
            <button class="button blue-button" @click="disable_nav">
              Sign up
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TasselHeader",
  title: "Tassel - Daily dose of Vocabulary",
};
</script>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";

const menu_open = ref(false);

function disable_nav() {
  menu_open.value = false;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#top_nav {
  padding-bottom: 3em;
}

#logo_text_col {
  margin-left: -2.5em;
}

.router-link-active {
  color: #9067c5;
}

#user_profile_card {
  border-width: 0.15em;
  border-radius: 3em;
  border-style: solid;
  border-color: #2662e3;
  padding: 0.5em 1em 0.5em 1em;
}

#profile_circle {
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.profile_currency {
  width: 1.5em;
  margin-top: 0.5em;
}
</style>
