<template>
  <div id="studyView" class="page">
    <h3 class="title is-3 has-text-centered">Today's Words</h3>
    <hr />
    <div
      class="notification"
      :class="[currentSentenceStudied ? 'is-primary is-light' : '']"
      v-if="current_word_data"
    >
      <div class="columns">
        <div class="column is-one is-narrow">
          <span class="tag is-primary">{{ current_study_counter }}</span>
        </div>
        <div class="column">
          <div class="block subtitle is-size-2">
            <b>{{ current_word_data.word }}</b>
          </div>
          <div id="study-question" class="is-size-4-desktop is-size-6-touch">
            <div class="block">
              <b>Definition:</b> {{ current_word_data.definition }}
            </div>
            <div class="block">
              <b>Example Sentence:</b>
              {{ current_word_data.example_sentence }}
            </div>
            <div class="block" v-if="comment">
              <b>Score: {{ score }}</b> {{ comment }}
            </div>
            <div
              v-if="submitIsLoading"
              class="notification is-primary is-flex is-justify-content-center"
            >
              {{ currentLoadingText }}
            </div>
          </div>
          <div class="field has-addons">
            <div class="control is-expanded">
              <input
                class="input is-size-4-desktop is-size-6-touch"
                v-model="currentSentence"
                type="text"
                @keyup.enter="submitSentence"
                :placeholder="`Type a sentence using ${current_word_data.word}...`"
                ref="sentenceInput"
              />
            </div>
            <div class="control">
              <button
                class="button is-info is-size-4-desktop is-size-6-touch"
                :class="{ loading: submitIsLoading }"
                @click="submitSentence"
                :disabled="!currentSentence"
              >
                <span v-if="!submitIsLoading">Submit</span>
                <span v-else>Reviewing...</span>
              </button>
            </div>
          </div>
        </div>
        <div id="next_button_col" class="column is-narrow">
          <button
            class="button is-size-4-desktop is-size-6-touch"
            @click="showNext"
            :disabled="!currentSentenceStudied || submitIsLoading"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import axios from "axios";
import { refresh_currency } from "../store";

const current_study_counter = ref(1);
const current_word_data = ref({});
const currentSentence = ref("");
const currentSentenceStudied = ref(false);
const sentenceInput = ref(null);
const score = ref(0);
const comment = ref("");
const submitIsLoading = ref(false);

//Vars relating to loading text
const loadingTextList = [
  "✨ Polishing your words for that extra sparkle",
  "💆️ Sentence spa time: Relax while your words get pampered!",
  "👀 Sentence scrutiny in progress. We're on it!",
  "🎵 Unveiling the carefully crafted symphony of your words",
  "🧠 Sending your sentences through the wisdom scanner!",
  "🧩 Assembling the sentence puzzle just for you",
  "💬 Whispering sweet edits to your sentences",
  "🍷 Words are like fine wine; they need time to mature.",
  "🌟 Holding the spotlight on your sentences for their big review!",
  "🎨 Chiseling away excess words to reveal the masterpiece within!",
  "💃 Tickling keyboards to perfect your sentence dance",
  "🌟 Your sentences are getting the VIP review treatment!",
  "✨ Putting the spotlight on your sentences as they shine!",
  "🔮 Sprinkling some grammar magic on your sentences",
  "📝 Tenderly caressing each word during the review process.",
  "🎻 Fine-tuning sentences with the precision of a maestro",
  "🍽️ Whisking your sentences to perfection like a culinary masterchef!",
  "🚀 Elevating your sentences to new heights with expert review.",
  "💃🕺 Locking sentences into a tango with the grammar checker",
  "❤️ Crafting your sentences with love, care, and a dash of review!",
];
const currentLoadingText = ref(loadingTextList[0]);

onMounted(async () => {
  get_next_word();
  nextTick(() => {
    sentenceInput.value.focus();
  });

  setInterval(() => {
    currentLoadingText.value =
      loadingTextList[Math.floor(Math.random() * loadingTextList.length)];
  }, 5000);
});

async function get_next_word() {
  const response = await axios.get("api/vocabListItems/");
  current_word_data.value = response.data;
}

async function showNext() {
  commitGold();
  await get_next_word();
  current_study_counter.value++;
  currentSentenceStudied.value = false;
  currentSentence.value = "";
  sentenceInput.value.focus();
  score.value = 0;
  comment.value = "";
}

function commitGold() {
  axios.post("api/study/commit").then((response) => {
    console.log(response);
    refresh_currency();
  });
}

async function submitSentence() {
  if (!submitIsLoading.value) {
    submitIsLoading.value = true;
    const payload = {
      word: current_word_data.value.word,
      sentence: currentSentence.value,
    };
    const response = await axios.post("api/study/verify_sentence", payload);

    const responseData = response.data;
    score.value = responseData.score;
    comment.value = responseData.feedback;

    console.log(response.data);

    // Allow student to pass if the score is good enough
    if (score.value > 85) {
      currentSentenceStudied.value = true;
    }
    submitIsLoading.value = false;
  }
}
</script>

<style>
#StudyView {
  margin: auto;
  margin-top: 3rem;
  max-width: 700px;
}
.icon {
  cursor: pointer;
}
#next_button_col {
  margin-top: auto;
}
</style>
