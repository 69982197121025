<template>
  <footer id="tassel-footer" class="footer mt-6">
    <div class="content has-text-white">
      <span>Something went wrong? </span>
      <router-link class="has-text-white" to="/contact">Contact Us</router-link>
    </div>
  </footer>
</template>

<style>
#tassel-footer {
  background-color: #2662e3;
}
</style>
