<template>
  <div>
    <section>
      <h1 class="title is-1 has-text-weight-bold has-text-centered">
        What is Tassel?
      </h1>
      <div class="hero-image">
        <div class="hero-text has-text-centered">
          <p>
            Tassel is a daily dose of vocabulary, spelling and grammar presented
            in a fun and interactive way. Made and run by accredited teachers,
            Tassel is for students of all ages: from primary school, to high
            school, and beyond!
          </p>
          <button id="hero-sign-up" class="button blue-button">Sign Up</button>
        </div>
      </div>
    </section>
    <section id="why">
      <div class="columns">
        <div class="column is-offset-1">
          <div class="content is-size-4">
            <h3 class="title is-3 has-text-weight-bold is-size-2">
              Why Tassel?
            </h3>
            <ul>
              <li>Learn new words with examples catered to your interests</li>
              <li>Receiving instant feedback and grading</li>
              <li>
                Test your vocabulary, spelling and grammar skills in exciting
                games
              </li>
              <li>Customise your own avatar and level it up</li>
            </ul>
          </div>
        </div>
        <div class="column">
          <img src="../assets/sampleexample.png" />
        </div>
      </div>
    </section>
    <section id="signup" class="hero-signup">
      <div id="signup-box-bg">
        <div id="signup-box">
          <h3 class="title is-3 has-text-centered">
            Sign Up Now for <strong>free</strong>
          </h3>
          <div class="field">
            <label class="label">Email</label>
            <p class="control has-icons-left has-icons-right">
              <input class="input" type="email" placeholder="Email" />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <p class="control has-icons-left">
              <input class="input" type="password" placeholder="Password" />
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </p>
          </div>
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <button class="button blue-button">Sign Up</button>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="about">
      <h3 class="title is-3 has-text-weight-bold">About Us</h3>
      <div class="block is-size-4">
        Tassel was founded by a teacher and a technologist who are passionate
        about helping students learn and grow in engaging ways, with a focus on
        vocabulary, spelling and grammar. After all, at Tassel, we believe that
        students learn best when having fun. That's why we cater to our
        students' interests, test students on their learning in stimulating
        ways, as well as offer awesome incentives for quality learning.
      </div>
      <div class="block is-size-4">
        Our name, Tassel, is a reference to the tassel on a graduation cap: our
        mission is to smooth the path to graduation by not only helping students
        excel, but have fun as well.
      </div>
      <div class="columns mt-3">
        <div class="column">
          <div class="placeholder-avatar center"></div>
        </div>
        <div class="column">
          <div class="placeholder-avatar center"></div>
        </div>
      </div>
    </section>
    <section id="footer"></section>
  </div>
</template>

<style>
/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/hero.png");

  /* Set a specific height */
  height: 600px;
  width: 100vw;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  color: white;
  font-size: 2.7em;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
}

#hero-sign-up {
  margin-top: 3em;
  font-size: 0.5em;
}

.hero-signup {
  padding-top: 3em;
  padding-bottom: 3em;

  width: 100vw;

  background-color: #2662e3;
}

#signup-box-bg {
  background-color: white;
  width: 50%;

  margin-left: auto;
  margin-right: auto;
  padding-top: 3em;
  padding-bottom: 3em;

  border-radius: 0.3em;
}

#signup-box {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

#signup .blue-button {
  font-size: 1.3em;
  width: 10em;
}

.field {
  margin-top: 3em;
}

section + section {
  margin-top: 8em;
}

#about {
  padding-left: 12%;
  padding-right: 12%;
}

.placeholder-avatar {
  width: 17em;
  height: 20em;
  border: black;
  border-style: solid;
}

#footer {
  background-color: #2662e3;
  height: 3em;
  width: 100vw;
}
</style>
