import { reactive, readonly, DeepReadonly } from "vue";
import axios from "axios";

interface StoreState {
  username: string;
  isLoggedIn: boolean;
  currency: {
    gold: number;
    tokens: number;
  };
}

// Make the state object reactive
const state = reactive<StoreState>({
  username: "NULL",
  isLoggedIn: false,
  currency: {
    gold: 0,
    tokens: 0,
  },
});

// Export the state object as read-only to prevent accidental modifications
export const store: DeepReadonly<StoreState> = readonly(state);

export function logIn(login_response: {
  username: string;
  currency: {
    gold: number;
    tokens: number;
  };
  env: string;
}): void {
  state.username = login_response.username;
  state.currency = login_response.currency;
  state.isLoggedIn = true;

  //Save env
  localStorage.setItem("env", login_response.env);
}

export function refresh_currency() {
  axios
    .get("/api/user/currency")
    .then((response) => {
      console.log(response);
      state.currency = response.data;
    })
    .catch((error) => {
      console.error("Error refreshing currency:", error);
    });
}
