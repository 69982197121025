<template>
  <title>Tassel</title>
  <header>
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
      rel="stylesheet"
    />
  </header>
  <tasselHeader />
  <router-view />
  <tasselFooter />
</template>

<script setup>
import { onMounted } from "vue";
import tasselHeader from "./components/TasselHeader.vue";
import tasselFooter from "./components/TasselFooter.vue";
import axios from "axios";
import { logIn } from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import "bulma/css/bulma.css";

onMounted(async () => {
  try {
    const response = await axios.post("api/authentication/checkLogin");
    console.log(response.data);
    logIn(response.data);
  } catch (err) {
    //Means we aren't logged in so dw about it
  }
});
</script>

<style>
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
